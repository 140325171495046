.arw {
  padding-left: 20px;
  background: url(../images/common/arw.png) left center no-repeat;
  &:hover {
    color: $dominant; } }
.side_nav {
  border-radius: 8px;
  background-color: #fff;
  h2 {
    padding: 10px 15px 5px;
    border-bottom: 5px solid $baseblue;
    color: $baseblue;
    @extend %fz20;
    font-weight: normal; }
  li:not(:first-child) {
    border-top: 1px dotted #ccc; }
  li:last-child a {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; }
  a {
    display: block;
    padding: 10px 15px;
    &:before {
      margin-right: 5px;
      vertical-align: middle;
      content: url(../images/news/arw.png); }
    &:hover,
    &.on {
      background-color: $tonic;
      color: #fff;
      &:before {
        content: url(../images/news/arw_on.png); } } } }
.notice_list {
  margin-bottom: 60px;
  border-top: 1px dotted #ccc;
  li {
    padding: 15px 0 15px 9em;
    border-bottom: 1px dotted #ccc; }
  .date {
    float: left;
    margin-left: -9em; } }
.faq_tab {
  height: 43px;
  margin-bottom: 30px;
  border-bottom: 3px solid $tonic;
  li {
    float: left;
    width: 200px;
    margin-left: 11px;
    text-align: center; }
  a {
    display: block;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #eaeaea;
    line-height: 40px;
    @extend %fz16;
    font-weight: bold;
    &:hover,
    &.on {
      background-color: $tonic; } }
  .small {
    @extend %fz12; } }
.qanda {
  dt,dd {
    padding-top: 30px;
    padding-left: 55px;
    &:before {
      float: left;
      margin-left: -55px;
      margin-top: -5px;
      content: url(../images/news/faq_a.png); } }
  dt {
    color: #069;
    @extend %fz18;
    font-weight: bold;
    &:before {
      content: url(../images/news/faq_q.png); } }
  dd {
    margin-bottom: 20px;
    padding-bottom: 30px;
    &:not(:last-child) {
      border-bottom: 1px dotted #ccc; } }
  a:link {
    text-decoration: underline; } }
.login_form {
  width: 500px;
  margin: 0 auto 60px;
  padding: 40px 30px;
  border: 5px solid #99d6eb;
  border-radius: 20px;
  label {
    display: inline-block;
    width: 7em;
    @extend %fz16;
    font-weight: bold; }
  input[type="text"],
  input[type="password"] {
    width: 290px;
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #ccc; }
  button {
    @extend .btn_back;
    border: none; } }
.logout {
  float: right;
  @extend %btn_base;
  width: auto;
  height: auto;
  line-height: normal;
  padding: 5px 10px; }
.catalog {
  h2 {
    margin-bottom: 20px;
    padding: 5px 10px;
    border: 5px solid #ffccb6;
    border-radius: 10px;
    background-color: #ffe0e5;
    @extend %fz20;
    select {
      float: right;
      @extend %fz14;
      font-weight: normal; }
    &.version {
      border-color: #7dd2d6;
      background-color: #b3e5e7;
      form {
        display: none; } } } }
.item_wrap {
  margin-right: -20px;
  margin-left: -20px;
  @extend %cf; }
// .item
//   margin-bottom: 40px
//   padding-left: 230px
//   @extend %cf
//   .tn
//     max-width: 200px
//     float: left
//     margin-left: -230px
//   h3
//     margin-bottom: 20px
.item {
  float: left;
  width: 260px;
  margin: 0 20px 40px;
  .imgwrap {
    // display: flex
    // justify-content: center
    // align-items: center
    display: table;
    table-layout: fixed;
    width: 260px;
    height: 260px;
    margin-bottom: 15px;
    // img
    //   flex: 0 0 auto
    > div {
      display: table-cell;
      box-sizing: content-box;
      width: 220px;
      height: 220px;
      padding: 19px;
      border: 1px solid #ccc;
      text-align: center;
      vertical-align: middle;
      max-width: 100%;
      max-height: 100%; } }
  h3 {
    margin-bottom: 1em; }
  a:hover img {
    opacity: .6; } }
.btn_pdf {
  @extend .btn_back;
  width: auto;
  padding: 0 15px;
  border-bottom: 3px solid #005199; }
.item_list {
  margin-bottom: 30px;
  h3 {
    margin-bottom: 15px;
    border-bottom: 2px solid #7dd2d6;
    @extend %fz20; }
  li {
    margin-bottom: 10px; } }
.forbid {
  li {
    margin-bottom: 1em; } }
.btn_form {
  @extend %btn_base;
  @extend %fz24;
  padding: 0 20px 10px;
  span {
    @extend %fz14; } }
.big {
  @extend %fz16;
  font-weight: bold; }
.tel_num {
  margin-bottom: .5em;
  color: #0066b3;
  font-size: 30px;
  font-weight: bold;
  img {
    margin-right: 5px;
    vertical-align: middle; } }
.recieve {
  display: table;
  @extend .big;
  dt,dd {
    display: table-cell;
    padding: 15px; }
  dt {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #0066b3;
    color: #fff; }
  dd {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #b3d2e8; } }
.usage {
  @extend %cf;
  margin-bottom: 20px; }
.support_list {
  @extend %cf;
  margin-left: -25px;
  li {
    float: left;
    width: 270px;
    padding: 4px;
    margin-bottom: 25px;
    margin-left: 25px;
    border: 1px solid #ccc;
    text-align: center;
    a {
      display: block;
      color: $dominant;
      @extend %fz16;
      font-weight: bold; } } }
.envelope {
  span {
    display: inline-block;
    margin-right: 40px;
    vertical-align: top; } }
.prof {
  color: $dominant; }
[lang="zh-cn"] {
  font-family: "Microsoft YaHei", "微软雅黑",'SimSun', sans-serif; }
.news .main_contents article > div:not(.related) a:link {
  text-decoration: underline; }
