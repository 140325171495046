.motto {
  .c {
    padding: 40px 0; } }
.biz_list {
  @extend %cf;
  margin-bottom: 20px;
  margin-left: -25px;
  li {
    float: left;
    width: 270px;
    margin-left: 25px;
    padding: 15px;
    border: 5px solid #deecff;
    border-radius: 16px;
    p:last-of-type {
      margin-bottom: 0; } } }
.amuse {
  margin-bottom: 15px;
  color: #2c71ab;
  img {
    margin-right: 5px;
    vertical-align: middle; } }
.game {
  @extend %cf;
  margin-bottom: 20px;
  .flo_l {
    margin-right: 15px; }
  h4 {
    margin-bottom: 15px; } }
.co {
  th,
  td {
    box-sizing: border-box;
    padding: 15px;
    vertical-align: top; }
  tr:not(:last-child) {
    th,
    td {
      border-bottom: 1px solid #ccc; } }
  th {
    width: 150px;
    font-weight: normal;
    text-align: center; }
  &.hist {
    th {
      padding-left: 0;
      text-align: left; }
    tr:last-child {
      th,
      td {
        border-bottom: 1px solid #ccc; } } } }
.map {
  @extend %cf;
  figure {
    float: right;
    margin-left: 20px;
    img {
      display: block;
      margin-bottom: 15px; } }
  h3 {
    @extend %fz18; } }
.call {
  color: #0066b3;
  @extend %fz16;
  font-weight: bold;
  img {
    margin-right: 5px;
    vertical-align: middle; } }
.ind {
  margin-bottom: 0;
  padding-left: 1em;
  text-indent: -1em;
  &.mb30 {
    margin-bottom: 30px; } }
.large {
  @extend %fz18;
  font-weight: bold; }
