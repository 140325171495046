.toys_main {
  float: right;
  width: 900px;
  margin-bottom: 60px;
  margin-left: 40px;
  a:hover img {
    opacity: .6; } }
.toys_sub {
  width: 260px;
  ul {
    padding: 0 10px;
    border-radius: 0 8px 8px 8px;
    background-color: $liteblue; }
  a {
    display: block; }
  li {
    padding: 10px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #fff; } }
  .menu_heading {
    color: #fff;
    @extend %fz16;
    font-weight: bold;
    cursor: pointer;
    &::before {
      margin-right: .5em;
      vertical-align: middle;
      content: url(../images/toys/marker.png); } }
  .submenu {
    display: none;
    border-radius: 6px;
    background-color: #fff;
    a {
      position: relative;
      padding: 10px;
      padding-right: 36px;
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px; }
      &:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px; }
      &:not(:first-child) {
        border-top: 1px solid #ccc; }
      &.on,
      &:hover {
        background-color: #fc0; }
      &::after {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
        content: '>'; } } } }
.toys_tab {
  margin-bottom: 40px;
  border-bottom: 3px solid $liteblue;
  @extend %cf;
  li {
    float: left;
    margin-right: 5px; }
  a {
    display: block;
    width: 260px;
    padding: 7px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #ccc;
    color: #fff;
    @extend %fz16;
    font-weight: bold;
    text-align: center;
    &.on,
    &:hover {
      background-color: $liteblue; } } }
.toys_category {
  margin-bottom: 40px; }
.pager {
  margin: 30px 0;
  text-align: center;
  a {
    display: inline-block;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 2px;
    background-color: $liteblue;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    &.on,
    &:hover {
      background-color: #f90; }
    &:nth-child(2) {
      margin-right: 10px; }
    &:nth-last-child(2) {
      margin-left: 10px; } } }
.toys_result {
  margin-bottom: 30px;
  .order {
    float: right;
    padding: 4px;
    border: 1px solid #ccc; } }
.toys_list {
  margin-bottom: 40px;
  margin-left: -25px;
  @extend %cf;
  li {
    position: relative;
    float: left;
    width: 270px;
    margin-left: 25px;
    margin-bottom: 25px;
    padding: 4px;
    border: 1px solid #ccc;
    .status, {
      width: 100px;
      height: 30px;
      line-height: 30px; } } }
.status {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: inline-block;
  border-bottom-right-radius: 10px;
  background-color: #666;
  color: #fff;
  @extend %fz16;
  text-align: center;
  &.recent {
    background-color: #f06; } }
.list_img {
  position: relative;
  width: 260px;
  height: 260px;
  text-align: center;
  img {
    max-width: 90%;
    max-height: 90%;
    @extend %img_tilt; }
  li:hover &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    border: 10px solid #ccc;
    content: ''; } }
.toys_data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 117px;
  padding: 8px;
  background-color: #e5e5e5;
  span {
    display: block; }
  .name,
  .prix {
    @extend %fz16;
    font-weight: bold; }
  .name {
    height: 3.2em;
    margin-bottom: .5em;
    overflow: hidden; }
  .prix {
    color: #c00; }
  .prix,
  .date {
    text-align: right; } }
.detail_img {
  position: relative;
  float: left;
  width: 430px;
  height: 430px;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  .status {
    width: 130px;
    height: 40px;
    line-height: 40px;
    @extend %fz20;
    font-weight: bold; }
  img {
    @extend %img_tilt; } }
.detail_info {
  float: left;
  width: 400px;
  margin-bottom: 30px;
  // dl
  //   margin-bottom: 10px
  // dt
  //   clear: left
  //   float: left
  //   height: 30px
  //   line-height: 30px
  //   margin-right: 1em
  //   padding: 0 10px
  //   border-radius: 6px
  //   background-color: $liteblue
  //   color: #fff
  // dd
  //   min-height: 30px
  //   padding-top: 5px
  //   overflow: hidden
  dl {
    display: table;
    table-layout: fixed;
    margin-bottom: 10px; }
  dt, dd {
    display: table-cell;
    padding: 5px; }
  dt {
    width: 160px;
    border-radius: 6px;
    background-color: #e5e5e5;
    text-align: center;
    vertical-align: middle; }
  dd {
    padding-left: 20px; } }
.related {
  margin-bottom: 40px;
  ul {
    margin-left: -15px;
    @extend %cf; }
  li {
    float: left;
    display: table;
    table-layout: fixed;
    width: 110px;
    height: 110px;
    margin-left: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc; }
  a {
    display: table-cell;
    box-sizing: border-box;
    width: 108px;
    height: 108px;
    padding: 10px;
    text-align: center;
    vertical-align: middle; } }
%btn_base {
  display: inline-block;
  background-color: $liteblue;
  color: #fff;
  border-radius: 8px;
  @extend %fz16;
  font-weight: bold;
  text-align: center; }
.btn_back {
  @extend %btn_base;
  width: 195px;
  height: 45px;
  line-height: 45px; }
.go_detail {
  @extend %fz16;
  font-weight: bold;
  img {
    margin-right: 5px;
    vertical-align: middle; } }
.yt {
  //float: right
  margin-bottom: 20px;
 }  //margin-left: 20px
