@import "reset";
@import "util";

$inner_w: 1200px;
$break: 640px;
$fontfamily_ja: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$baseblue: #004f99;
$liteblue: #39c;
$tonic: #f90;
$dominant: #f60;

.sp {
  display: none; }
@include max-screen {
  .pc,
  .sublink,
  .path {
    display: none; }
  .sp {
    display: block; } }
img {
  max-width: 100%;
  max-height: 100%; }
a {
  color: #333;
  text-decoration: none; }
.tdu {
  color: inherit;
  text-decoration: underline; }
section,
article {
  margin-bottom: 60px; }
//html,body
//height: 100%
body {
  min-width: $inner_w;
  background: #f3eedb url(../images/common/bg.png);
  color: #333;
  font-family: $fontfamily_ja;
  line-height: 1.6;
  word-wrap: break-word;
  @extend %fz14;
  @include max-screen {
    padding-top: 0; } }
.inner {
  width: $inner_w;
  margin: 0 auto;
  @extend %cf;
  @include max-screen {
    width: auto;
    padding: 0 10px; } }
.hd {
  height: 100px;
  background-color: #fff; }
.hd_logo {
  float: left;
  margin-top: 20px; }
.hd_control {
  padding-top: 33px;
  text-align: right;
  img {
    vertical-align: middle; }
  a {
    display: inline-block;
    margin-left: 20px;
    &:not(.e) {
      @extend %fz16;
      font-weight: bold; }
    &.e {
      @extend %fz12;
      img {
        margin-top: -3px; } }
    &:hover {
      color: #f15a24; } } }
.menu {
  border-top: 5px solid #004699;
  background-color: #fff;
  li {
    float: left;
    width: 300px;
    height: 75px;
    overflow: hidden;
    img {
      max-width: none;
      max-height: none; } }
  .n2 img {
    margin-left: -300px; }
  .n3 img {
    margin-left: -600px; }
  .n4 img {
    margin-left: -900px; }
  a:hover img {
    margin-top: -75px; } }
.toys .n1 img,
.members .n2 img,
.support .n3 img,
.corporate .n4 img {
  margin-top: -75px; }
.hd_nav {
  height: 70px;
  padding-top: 10px;
  background: #d9e5ff;
  ul {
    position: relative;
    @extend %cf; }
  li:not(.nav_search) {
    float: left;
    height: 50px;
    line-height: 50px; }
  img {
    vertical-align: middle; } }
.hd_nav_lead {
  width: 100px;
  img {
    margin-top: 5px; } }
.hd_nav_btn {
  margin-right: 5px;
  > a {
    display: block;
    box-sizing: border-box;
    width: 200px;
    height: 50px;
    overflow: hidden;
    img {
      max-height: none; } }
  &:hover {
    img {
      margin-top: -50px; }
    .nav_submenu {
      //display: block
      display: flex;
      flex-wrap: wrap; } } }
$one_third: calc(100% * (1 / 3));
.nav_submenu {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 100px;
  display: none;
  width: 912px;
  line-height: 1.2;
  border: 6px solid #06c;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff;
  @extend %cf;
  overflow: hidden;
  a {
    //float: left
    flex-basis: $one_third;
    //width: 300px
    padding: 20px 0;
    color: #06c;
    @extend %fz20;
    font-weight: bold;
    text-align: center;
    &:hover {
      background-color: #eaeaea; } } }
.nav_search {
  float: right;
  width: 500px;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  border-radius: 6px;
  background-color: #fff;
  input {
    &[type="text"] {
      padding: 4px;
      padding-left: 20px;
      //width: 430px
      width: 414px;
      border: none;
      @extend %fz16; }
    &[type="image"] {
      float: right; } } }
.path {
  margin-bottom: 20px;
  padding: 10px 0;
  @extend %fz12;
  a::after {
    margin: 0 1em;
    content: '＞'; } }
.main {
  float: left;
  width: 900px;
  margin-right: 40px;
  margin-bottom: 60px; }
.main_contents {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  article {
    margin-bottom: 40px; } }
.sub {
  float: left;
  width: 260px;
  margin-bottom: 60px;
  a:hover img {
    opacity: .6; } }
.shade {
  display: block;
  border-radius: 10px;
  box-shadow: 0 3px 3px rgba(0,0,0,.4); }
.side_notice {
  background-color: #fff;
  border-radius: 10px;
  h2 {
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid $baseblue; }
  ul {
    padding: 10px; }
  li {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px dotted #999; }
  a:hover {
    text-decoration: underline; }
  .r {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
    img {
      vertical-align: middle; } } }
.side_bnr {
  margin-bottom: 40px;
  a {
    display: block;
    margin-bottom: 20px; } }
.side_link {
  margin-bottom: 40px;
  h2 {
    margin-bottom: 15px;
    padding: 5px;
    border-bottom: 5px solid $baseblue;
    text-align: center; }
  a {
    display: block;
    //width: 260px
    //height: 80px
    margin-bottom: 15px; } }
.ft {
  padding: 15px 0;
  background-color: #fff; }
.ft_unit {
  float: left;
  width: 25%;
  min-height: 190px;
  padding-left: 22px;
  &:not(:first-child) {
    border-left: 1px dotted #ccc; }
  ul {
    li:before {
      margin-right: .5em;
      content: '-'; }
    a:hover {
      text-decoration: underline; } } }
.ft_unit_title {
  display: inline-block;
  width: 255px;
  margin-bottom: 15px;
  border-bottom: 5px solid $baseblue;
  color: $baseblue;
  @extend %fz18;
  font-weight: bold;
  &:hover {
    color: $dominant; } }
.ft_address {
  padding: 15px 0;
  background-color: $baseblue;
  color: #fff;
  @extend %fz10;
  address {
    margin-bottom: 1em; } }
.pagetop {
  position: fixed;
  right: 20px;
  bottom: 95px;
  z-index: 3; }
.article_title {
  margin-bottom: 20px;
  padding: 10px 20px 5px;
  border-bottom: 5px solid $baseblue;
  h1 {
    display: inline; }
  .go_list {
    float: right;
    line-height: 1;
    margin-top: 15px;
    img {
      margin-right: 5px;
      vertical-align: middle; } } }
.misc_title {
  @extend .article_title;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
  img {
    margin-right: 10px;
    vertical-align: middle; }
  .main_contents & {
    margin-right: -20px;
    margin-left: -20px;
    padding-top: 0; } }
.toys_title {
  margin-bottom: 40px;
  padding-left: 10px;
  border-left: 10px solid $liteblue;
  font-size: 30px; }
.detail_name {
  margin-bottom: 30px;
  @extend %fz24; }
.detail_title {
  margin-bottom: 20px;
  @extend %fz20; }
.alarm {
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: $tonic;
  color: #fff;
  @extend %fz20; }
.usage_title {
  margin-bottom: 20px;
  color: #069;
  @extend %fz20; }

@import "home";
@import "toys";
@import "misc";
@import "form";
@import "corporate";
