//.swiper-container
//width: 100%
//height: 330px
//margin: 20px auto
//.home &
//  //overflow: visible
//.swiper-slide
//  width: 60%
//  text-align: center
.slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  z-index: 2;
  display: block;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }
.slick {
  margin: 20px 0 60px;
  .slick-arrow {
    top: 50%;
    left: 50%;
    width: 39px;
    height: 39px;
    &.prev {
      transform: translate(-580px, -50%); }//1200 / 2 - 20
    &.next {
      transform: translate(541px, -50%); } } }//1200 / 2 -width - 20
.slide {
  //width: 1200px
  //height: 320px
  margin: 0 10px;
  text-align: center;
  &.slick-slide img {
    display: inline-block;
 }    //width: 1200px
  a:hover img,
  &:not(.slick-current) {
    opacity: .4; } }
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  list-style: none;
  text-align: center; }
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  cursor: pointer; }
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }
.slick-dots li button:before {
  font-size: 32px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }
.home_article {
  position: relative;
  background-color: #fff;
  padding: 0 30px 1px;
  border-radius: 20px;
  .article_title {
    margin-right: -30px;
    margin-left: -30px; }
  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    top: 90%;
    bottom: 15px;
    left: 10px;
    width: 50%;
    box-shadow: 0 15px 10px rgba(0,0,0,.4);
    transform: rotate(-2deg);
    content: ''; }
  &:after {
    right: 10px;
    left: auto;
    transform: rotate(2deg); } }
.series_list {
  margin-right: -20px;
  @extend %cf;
  &:first-of-type {
    margin-bottom: 30px; }
  li {
    float: left;
    width: 152px;
    height: 70px;
    margin-right: 20px;
    margin-bottom: 20px; }
  a:hover img {
    opacity: .6; } }
.pickup_list {
  margin: 0 -15px 15px;
  @extend %cf;
  .pu_slide {
    float: left;
    width: 280px;
    margin-left: 15px; }
  .slick-arrow {
    bottom: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    &.punav_prev {
      left: 0; }
    &.punav_next {
      right: 0; } }
  .slick-slide img {
    display: inline-block; } }
%img_tilt {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }
.pickup_img {
  position: relative;
  width: 280px;
  height: 280px;
  border: 2px solid #00adff;
  text-align: center;
  img {
    max-width: 90%;
    max-height: 90%;
    @extend %img_tilt; }
  .pu_slide:hover &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    border: 10px solid #00adff;
    content: ''; } }
.pickup_name {
  height: 40px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: $baseblue;
  @extend %fz16;
  font-weight: bold;
  overflow: hidden; }
.news_list {
  li {
    min-height: 70px;
    margin-bottom: 20px;
    @extend %cf;
    &:not(:last-child) {
      border-bottom: 1px dotted #ccc; } }
  a:hover {
    text-decoration: underline; } }
.news_img {
  float: left;
  position: relative;
  width: 82px;
  height: 82px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
  a:hover & {
    border-color: transparent; }
  a:hover &::before {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 5px solid #00adff;
    content: ''; }
  img {
    max-width: 90%;
    max-height: 90%;
    @extend %img_tilt; } }
