// ====== utility ======
%fz10 {
  font-size: 10px;
  font-size: .625rem; }
%fz11 {
  font-size: 11px;
  font-size: .6875rem; }
%fz12 {
  font-size: 12px;
  font-size: .75rem; }
%fz13 {
  font-size: 13px;
  font-size: .8125rem; }
%fz14 {
  font-size: 14px;
  font-size: .875rem; }
%fz15 {
  font-size: 15px;
  font-size: .9375rem; }
%fz16 {
  font-size: 16px;
  font-size: 1rem; }
%fz18 {
  font-size: 18px;
  font-size: 1.125rem; }
%fz20 {
  font-size: 20px;
  font-size: 1.25rem; }
%fz22 {
  font-size: 22px;
  font-size: 1.375rem; }
%fz24 {
  font-size: 24px;
  font-size: 1.5rem; }
.mb40 {
  margin-bottom: 40px; }
.mb30 {
  margin-bottom: 30px; }
.r {
  text-align: right; }
.l {
  text-align: left; }
.c {
  text-align: center; }
.flo_l {
  float: left; }
.flo_r {
  float: right; }
.emph,
.alert,
.bold {
  font-weight: bold; }
.attention,
.alert {
  color: #c00; }
.disc {
  list-style-type: disc;
  margin-bottom: 1em;
  padding-left: 1.4em; }
.hide {
  display: none; }
.clear {
  clear: both; }
/* micro clearfix */
%cf {
  &:before,
  &:after {
    content: '';
    display: table; }
  &:after {
    clear: both; } }
.cf {
  @extend %cf; }

@mixin max-screen($break-point: 640px) {
  @media screen and (max-width: $break-point) {
    @content; } }
@mixin min-screen($break-point: 641px) {
  @media screen and (min-width: $break-point) {
    @content; } }
