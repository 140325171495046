article, aside, figure, footer, header, nav, section, main {
  display: block; }
body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,pre,form,input,img,
article, aside, figure, footer, header, nav, section, main {
  margin: 0;
  padding: 0; }
div, section, main, article, aside, figure, nav, header, footer, p, ul, li, dl, dt, dd, td, th {
  box-sizing: border-box; }
table {
  width: 100%;
  border-spacing: 0;
  font-size: 100%; }
img,object {
  border: 0;
  vertical-align: top; }
ul {
  list-style: none; }
ol {
  margin: 0 0 1em;
  padding: 0 0 0 1.4em; }
caption,th {
  text-align: left; }
h1,h2,h3,h4,h5,h6,select {
  font-size: 100%; }
em,address,i {
  font-style: normal; }
p {
  margin: 0 0 1em;
  padding: 0; }
