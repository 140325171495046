.btn_conf {
  box-sizing: border-box;
  @extend %btn_base;
  width: 250px;
  height: 50px;
  line-height: 50px;
  background-color: $dominant;
  border: none;
  border-bottom: 3px solid #930;
  font-family: inherit;
  @extend %fz20; }
.btn_fix {
  @extend .btn_conf;
  margin-right: 1em;
  background-color: $liteblue;
  border-bottom-color: #2466ae; }
.inquiry {
  dl {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 15px; }
  dt {
    display: table-cell;
    width: 270px;
    padding: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #ffcc80;
    vertical-align: top; }
  dd {
    display: table-cell;
    padding: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #ffeaca; }
  input[type="text"],
  textarea,
  select {
    box-sizing: border-box;
    padding: 10px;
    border: none;
    border-radius: 6px; }
  .label {
    margin: 0 1em; }
  label {
    margin-right: 1em; } }
input.full,
textarea.full {
  width: 100%;
  + img {
    margin-top: 10px;
    margin-right: 10px;
    + p {
      margin-top: 10px; } } }
input.short {
  width: 8em;
  margin-right: 10px;
  &:not(:first-child) {
    margin-left: 10px; } }
.address {
  select {
    margin-bottom: 10px; }
  input.full:not(:last-child) {
    margin-bottom: 10px; } }
.btns {
  margin: 40px 0;
  text-align: center; }
.note {
  padding: 15px;
  border-radius: 8px;
  background-color: #e6ecea; }
.thanks {
  padding: 100px 0;
  text-align: center;
  p {
    margin-bottom: 80px; } }
