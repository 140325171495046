@charset "UTF-8";
article, aside, figure, footer, header, nav, section, main {
  display: block;
}

body, div, dl, dt, dd, ul, li, h1, h2, h3, h4, h5, pre, form, input, img,
article, aside, figure, footer, header, nav, section, main {
  margin: 0;
  padding: 0;
}

div, section, main, article, aside, figure, nav, header, footer, p, ul, li, dl, dt, dd, td, th {
  box-sizing: border-box;
}

table {
  width: 100%;
  border-spacing: 0;
  font-size: 100%;
}

img, object {
  border: 0;
  vertical-align: top;
}

ul {
  list-style: none;
}

ol {
  margin: 0 0 1em;
  padding: 0 0 0 1.4em;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, select {
  font-size: 100%;
}

em, address, i {
  font-style: normal;
}

p {
  margin: 0 0 1em;
  padding: 0;
}

.ft_address {
  font-size: 10px;
  font-size: .625rem;
}

.hd_control a.e, .path, .faq_tab .small {
  font-size: 12px;
  font-size: .75rem;
}

body, .catalog h2 select, .btn_form span {
  font-size: 14px;
  font-size: .875rem;
}

.hd_control a:not(.e), .nav_search input[type="text"], .pickup_name, .toys_sub .menu_heading, .toys_tab a, .status, .toys_data .name,
.toys_data .prix, .btn_back, .login_form button, .btn_pdf, .logout, .btn_form, .btn_conf, .btn_fix, .go_detail, .faq_tab a, .login_form label, .big, .recieve, .support_list li a, .call {
  font-size: 16px;
  font-size: 1rem;
}

.ft_unit_title, .qanda dt, .map h3, .large {
  font-size: 18px;
  font-size: 1.125rem;
}

.nav_submenu a, .detail_title, .alarm, .usage_title, .detail_img .status, .side_nav h2, .catalog h2, .item_list h3, .btn_conf, .btn_fix {
  font-size: 20px;
  font-size: 1.25rem;
}

.detail_name, .btn_form {
  font-size: 24px;
  font-size: 1.5rem;
}

.mb40 {
  margin-bottom: 40px;
}

.mb30 {
  margin-bottom: 30px;
}

.r {
  text-align: right;
}

.l {
  text-align: left;
}

.c {
  text-align: center;
}

.flo_l {
  float: left;
}

.flo_r {
  float: right;
}

.emph,
.alert,
.bold {
  font-weight: bold;
}

.attention,
.alert {
  color: #c00;
}

.disc {
  list-style-type: disc;
  margin-bottom: 1em;
  padding-left: 1.4em;
}

.hide {
  display: none;
}

.clear {
  clear: both;
}

/* micro clearfix */
.cf:before, .inner:before, .hd_nav ul:before, .nav_submenu:before, .series_list:before, .pickup_list:before, .news_list li:before, .toys_tab:before, .toys_list:before, .related ul:before, .item_wrap:before, .usage:before, .support_list:before, .biz_list:before, .game:before, .map:before, .cf:after, .inner:after, .hd_nav ul:after, .nav_submenu:after, .series_list:after, .pickup_list:after, .news_list li:after, .toys_tab:after, .toys_list:after, .related ul:after, .item_wrap:after, .usage:after, .support_list:after, .biz_list:after, .game:after, .map:after {
  content: '';
  display: table;
}

.cf:after, .inner:after, .hd_nav ul:after, .nav_submenu:after, .series_list:after, .pickup_list:after, .news_list li:after, .toys_tab:after, .toys_list:after, .related ul:after, .item_wrap:after, .usage:after, .support_list:after, .biz_list:after, .game:after, .map:after {
  clear: both;
}

.sp {
  display: none;
}

@media screen and (max-width: 640px) {
  .pc,
  .sublink,
  .path {
    display: none;
  }
  .sp {
    display: block;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  color: #333;
  text-decoration: none;
}

.tdu {
  color: inherit;
  text-decoration: underline;
}

section,
article {
  margin-bottom: 60px;
}

body {
  min-width: 1200px;
  background: #f3eedb url(../images/common/bg.png);
  color: #333;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  line-height: 1.6;
  word-wrap: break-word;
}

@media screen and (max-width: 640px) {
  body {
    padding-top: 0;
  }
}

.inner {
  width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 640px) {
  .inner {
    width: auto;
    padding: 0 10px;
  }
}

.hd {
  height: 100px;
  background-color: #fff;
}

.hd_logo {
  float: left;
  margin-top: 20px;
}

.hd_control {
  padding-top: 33px;
  text-align: right;
}

.hd_control img {
  vertical-align: middle;
}

.hd_control a {
  display: inline-block;
  margin-left: 20px;
}

.hd_control a:not(.e) {
  font-weight: bold;
}

.hd_control a.e img {
  margin-top: -3px;
}

.hd_control a:hover {
  color: #f15a24;
}

.menu {
  border-top: 5px solid #004699;
  background-color: #fff;
}

.menu li {
  float: left;
  width: 300px;
  height: 75px;
  overflow: hidden;
}

.menu li img {
  max-width: none;
  max-height: none;
}

.menu .n2 img {
  margin-left: -300px;
}

.menu .n3 img {
  margin-left: -600px;
}

.menu .n4 img {
  margin-left: -900px;
}

.menu a:hover img {
  margin-top: -75px;
}

.toys .n1 img,
.members .n2 img,
.support .n3 img,
.corporate .n4 img {
  margin-top: -75px;
}

.hd_nav {
  height: 70px;
  padding-top: 10px;
  background: #d9e5ff;
}

.hd_nav ul {
  position: relative;
}

.hd_nav li:not(.nav_search) {
  float: left;
  height: 50px;
  line-height: 50px;
}

.hd_nav img {
  vertical-align: middle;
}

.hd_nav_lead {
  width: 100px;
}

.hd_nav_lead img {
  margin-top: 5px;
}

.hd_nav_btn {
  margin-right: 5px;
}

.hd_nav_btn > a {
  display: block;
  box-sizing: border-box;
  width: 200px;
  height: 50px;
  overflow: hidden;
}

.hd_nav_btn > a img {
  max-height: none;
}

.hd_nav_btn:hover img {
  margin-top: -50px;
}

.hd_nav_btn:hover .nav_submenu {
  display: flex;
  flex-wrap: wrap;
}

.nav_submenu {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 100px;
  display: none;
  width: 912px;
  line-height: 1.2;
  border: 6px solid #06c;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff;
  overflow: hidden;
}

.nav_submenu a {
  flex-basis: calc(100% * (1 / 3));
  padding: 20px 0;
  color: #06c;
  font-weight: bold;
  text-align: center;
}

.nav_submenu a:hover {
  background-color: #eaeaea;
}

.nav_search {
  float: right;
  width: 500px;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  border-radius: 6px;
  background-color: #fff;
}

.nav_search input[type="text"] {
  padding: 4px;
  padding-left: 20px;
  width: 414px;
  border: none;
}

.nav_search input[type="image"] {
  float: right;
}

.path {
  margin-bottom: 20px;
  padding: 10px 0;
}

.path a::after {
  margin: 0 1em;
  content: '＞';
}

.main {
  float: left;
  width: 900px;
  margin-right: 40px;
  margin-bottom: 60px;
}

.main_contents {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
}

.main_contents article {
  margin-bottom: 40px;
}

.sub {
  float: left;
  width: 260px;
  margin-bottom: 60px;
}

.sub a:hover img {
  opacity: .6;
}

.shade {
  display: block;
  border-radius: 10px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.side_notice {
  background-color: #fff;
  border-radius: 10px;
}

.side_notice h2 {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-bottom: 5px solid #004f99;
}

.side_notice ul {
  padding: 10px;
}

.side_notice li {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #999;
}

.side_notice a:hover {
  text-decoration: underline;
}

.side_notice .r {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.side_notice .r img {
  vertical-align: middle;
}

.side_bnr {
  margin-bottom: 40px;
}

.side_bnr a {
  display: block;
  margin-bottom: 20px;
}

.side_link {
  margin-bottom: 40px;
}

.side_link h2 {
  margin-bottom: 15px;
  padding: 5px;
  border-bottom: 5px solid #004f99;
  text-align: center;
}

.side_link a {
  display: block;
  margin-bottom: 15px;
}

.ft {
  padding: 15px 0;
  background-color: #fff;
}

.ft_unit {
  float: left;
  width: 25%;
  min-height: 190px;
  padding-left: 22px;
}

.ft_unit:not(:first-child) {
  border-left: 1px dotted #ccc;
}

.ft_unit ul li:before {
  margin-right: .5em;
  content: '-';
}

.ft_unit ul a:hover {
  text-decoration: underline;
}

.ft_unit_title {
  display: inline-block;
  width: 255px;
  margin-bottom: 15px;
  border-bottom: 5px solid #004f99;
  color: #004f99;
  font-weight: bold;
}

.ft_unit_title:hover {
  color: #f60;
}

.ft_address {
  padding: 15px 0;
  background-color: #004f99;
  color: #fff;
}

.ft_address address {
  margin-bottom: 1em;
}

.pagetop {
  position: fixed;
  right: 20px;
  bottom: 95px;
  z-index: 3;
}

.article_title, .misc_title {
  margin-bottom: 20px;
  padding: 10px 20px 5px;
  border-bottom: 5px solid #004f99;
}

.article_title h1, .misc_title h1 {
  display: inline;
}

.article_title .go_list, .misc_title .go_list {
  float: right;
  line-height: 1;
  margin-top: 15px;
}

.article_title .go_list img, .misc_title .go_list img {
  margin-right: 5px;
  vertical-align: middle;
}

.misc_title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
}

.misc_title img {
  margin-right: 10px;
  vertical-align: middle;
}

.main_contents .misc_title {
  margin-right: -20px;
  margin-left: -20px;
  padding-top: 0;
}

.toys_title {
  margin-bottom: 40px;
  padding-left: 10px;
  border-left: 10px solid #39c;
  font-size: 30px;
}

.detail_name {
  margin-bottom: 30px;
}

.detail_title {
  margin-bottom: 20px;
}

.alarm {
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #f90;
  color: #fff;
}

.usage_title {
  margin-bottom: 20px;
  color: #069;
}

.slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  z-index: 2;
  display: block;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick {
  margin: 20px 0 60px;
}

.slick .slick-arrow {
  top: 50%;
  left: 50%;
  width: 39px;
  height: 39px;
}

.slick .slick-arrow.prev {
  transform: translate(-580px, -50%);
}

.slick .slick-arrow.next {
  transform: translate(541px, -50%);
}

.slide {
  margin: 0 10px;
  text-align: center;
}

.slide.slick-slide img {
  display: inline-block;
}

.slide a:hover img, .slide:not(.slick-current) {
  opacity: .4;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 32px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

.home_article {
  position: relative;
  background-color: #fff;
  padding: 0 30px 1px;
  border-radius: 20px;
}

.home_article .article_title, .home_article .misc_title {
  margin-right: -30px;
  margin-left: -30px;
}

.home_article:before, .home_article:after {
  position: absolute;
  z-index: -1;
  top: 90%;
  bottom: 15px;
  left: 10px;
  width: 50%;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.4);
  transform: rotate(-2deg);
  content: '';
}

.home_article:after {
  right: 10px;
  left: auto;
  transform: rotate(2deg);
}

.series_list {
  margin-right: -20px;
}

.series_list:first-of-type {
  margin-bottom: 30px;
}

.series_list li {
  float: left;
  width: 152px;
  height: 70px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.series_list a:hover img {
  opacity: .6;
}

.pickup_list {
  margin: 0 -15px 15px;
}

.pickup_list .pu_slide {
  float: left;
  width: 280px;
  margin-left: 15px;
}

.pickup_list .slick-arrow {
  bottom: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
}

.pickup_list .slick-arrow.punav_prev {
  left: 0;
}

.pickup_list .slick-arrow.punav_next {
  right: 0;
}

.pickup_list .slick-slide img {
  display: inline-block;
}

.pickup_img img, .news_img img, .list_img img, .detail_img img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.pickup_img {
  position: relative;
  width: 280px;
  height: 280px;
  border: 2px solid #00adff;
  text-align: center;
}

.pickup_img img {
  max-width: 90%;
  max-height: 90%;
}

.pu_slide:hover .pickup_img::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  border: 10px solid #00adff;
  content: '';
}

.pickup_name {
  height: 40px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #004f99;
  font-weight: bold;
  overflow: hidden;
}

.news_list li {
  min-height: 70px;
  margin-bottom: 20px;
}

.news_list li:not(:last-child) {
  border-bottom: 1px dotted #ccc;
}

.news_list a:hover {
  text-decoration: underline;
}

.news_img {
  float: left;
  position: relative;
  width: 82px;
  height: 82px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
}

a:hover .news_img {
  border-color: transparent;
}

a:hover .news_img::before {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 5px solid #00adff;
  content: '';
}

.news_img img {
  max-width: 90%;
  max-height: 90%;
}

.toys_main {
  float: right;
  width: 900px;
  margin-bottom: 60px;
  margin-left: 40px;
}

.toys_main a:hover img {
  opacity: .6;
}

.toys_sub {
  width: 260px;
}

.toys_sub ul {
  padding: 0 10px;
  border-radius: 0 8px 8px 8px;
  background-color: #39c;
}

.toys_sub a {
  display: block;
}

.toys_sub li {
  padding: 10px 0;
}

.toys_sub li:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.toys_sub .menu_heading {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.toys_sub .menu_heading::before {
  margin-right: .5em;
  vertical-align: middle;
  content: url(../images/toys/marker.png);
}

.toys_sub .submenu {
  display: none;
  border-radius: 6px;
  background-color: #fff;
}

.toys_sub .submenu a {
  position: relative;
  padding: 10px;
  padding-right: 36px;
}

.toys_sub .submenu a:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.toys_sub .submenu a:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.toys_sub .submenu a:not(:first-child) {
  border-top: 1px solid #ccc;
}

.toys_sub .submenu a.on, .toys_sub .submenu a:hover {
  background-color: #fc0;
}

.toys_sub .submenu a::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  content: '>';
}

.toys_tab {
  margin-bottom: 40px;
  border-bottom: 3px solid #39c;
}

.toys_tab li {
  float: left;
  margin-right: 5px;
}

.toys_tab a {
  display: block;
  width: 260px;
  padding: 7px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.toys_tab a.on, .toys_tab a:hover {
  background-color: #39c;
}

.toys_category {
  margin-bottom: 40px;
}

.pager {
  margin: 30px 0;
  text-align: center;
}

.pager a {
  display: inline-block;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin: 0 2px;
  line-height: 30px;
  border-radius: 2px;
  background-color: #39c;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
}

.pager a.on, .pager a:hover {
  background-color: #f90;
}

.pager a:nth-child(2) {
  margin-right: 10px;
}

.pager a:nth-last-child(2) {
  margin-left: 10px;
}

.toys_result {
  margin-bottom: 30px;
}

.toys_result .order {
  float: right;
  padding: 4px;
  border: 1px solid #ccc;
}

.toys_list {
  margin-bottom: 40px;
  margin-left: -25px;
}

.toys_list li {
  position: relative;
  float: left;
  width: 270px;
  margin-left: 25px;
  margin-bottom: 25px;
  padding: 4px;
  border: 1px solid #ccc;
}

.toys_list li .status {
  width: 100px;
  height: 30px;
  line-height: 30px;
}

.status {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: inline-block;
  border-bottom-right-radius: 10px;
  background-color: #666;
  color: #fff;
  text-align: center;
}

.status.recent {
  background-color: #f06;
}

.list_img {
  position: relative;
  width: 260px;
  height: 260px;
  text-align: center;
}

.list_img img {
  max-width: 90%;
  max-height: 90%;
}

li:hover .list_img::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  border: 10px solid #ccc;
  content: '';
}

.toys_data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 117px;
  padding: 8px;
  background-color: #e5e5e5;
}

.toys_data span {
  display: block;
}

.toys_data .name,
.toys_data .prix {
  font-weight: bold;
}

.toys_data .name {
  height: 3.2em;
  margin-bottom: .5em;
  overflow: hidden;
}

.toys_data .prix {
  color: #c00;
}

.toys_data .prix,
.toys_data .date {
  text-align: right;
}

.detail_img {
  position: relative;
  float: left;
  width: 430px;
  height: 430px;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
}

.detail_img .status {
  width: 130px;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}

.detail_info {
  float: left;
  width: 400px;
  margin-bottom: 30px;
}

.detail_info dl {
  display: table;
  table-layout: fixed;
  margin-bottom: 10px;
}

.detail_info dt, .detail_info dd {
  display: table-cell;
  padding: 5px;
}

.detail_info dt {
  width: 160px;
  border-radius: 6px;
  background-color: #e5e5e5;
  text-align: center;
  vertical-align: middle;
}

.detail_info dd {
  padding-left: 20px;
}

.related {
  margin-bottom: 40px;
}

.related ul {
  margin-left: -15px;
}

.related li {
  float: left;
  display: table;
  table-layout: fixed;
  width: 110px;
  height: 110px;
  margin-left: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
}

.related a {
  display: table-cell;
  box-sizing: border-box;
  width: 108px;
  height: 108px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}

.btn_back, .login_form button, .btn_pdf, .logout, .btn_form, .btn_conf, .btn_fix {
  display: inline-block;
  background-color: #39c;
  color: #fff;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
}

.btn_back, .login_form button, .btn_pdf {
  width: 195px;
  height: 45px;
  line-height: 45px;
}

.go_detail {
  font-weight: bold;
}

.go_detail img {
  margin-right: 5px;
  vertical-align: middle;
}

.yt {
  margin-bottom: 20px;
}

.arw {
  padding-left: 20px;
  background: url(../images/common/arw.png) left center no-repeat;
}

.arw:hover {
  color: #f60;
}

.side_nav {
  border-radius: 8px;
  background-color: #fff;
}

.side_nav h2 {
  padding: 10px 15px 5px;
  border-bottom: 5px solid #004f99;
  color: #004f99;
  font-weight: normal;
}

.side_nav li:not(:first-child) {
  border-top: 1px dotted #ccc;
}

.side_nav li:last-child a {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.side_nav a {
  display: block;
  padding: 10px 15px;
}

.side_nav a:before {
  margin-right: 5px;
  vertical-align: middle;
  content: url(../images/news/arw.png);
}

.side_nav a:hover, .side_nav a.on {
  background-color: #f90;
  color: #fff;
}

.side_nav a:hover:before, .side_nav a.on:before {
  content: url(../images/news/arw_on.png);
}

.notice_list {
  margin-bottom: 60px;
  border-top: 1px dotted #ccc;
}

.notice_list li {
  padding: 15px 0 15px 9em;
  border-bottom: 1px dotted #ccc;
}

.notice_list .date {
  float: left;
  margin-left: -9em;
}

.faq_tab {
  height: 43px;
  margin-bottom: 30px;
  border-bottom: 3px solid #f90;
}

.faq_tab li {
  float: left;
  width: 200px;
  margin-left: 11px;
  text-align: center;
}

.faq_tab a {
  display: block;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: #eaeaea;
  line-height: 40px;
  font-weight: bold;
}

.faq_tab a:hover, .faq_tab a.on {
  background-color: #f90;
}

.qanda dt, .qanda dd {
  padding-top: 30px;
  padding-left: 55px;
}

.qanda dt:before, .qanda dd:before {
  float: left;
  margin-left: -55px;
  margin-top: -5px;
  content: url(../images/news/faq_a.png);
}

.qanda dt {
  color: #069;
  font-weight: bold;
}

.qanda dt:before {
  content: url(../images/news/faq_q.png);
}

.qanda dd {
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.qanda dd:not(:last-child) {
  border-bottom: 1px dotted #ccc;
}

.qanda a:link {
  text-decoration: underline;
}

.login_form {
  width: 500px;
  margin: 0 auto 60px;
  padding: 40px 30px;
  border: 5px solid #99d6eb;
  border-radius: 20px;
}

.login_form label {
  display: inline-block;
  width: 7em;
  font-weight: bold;
}

.login_form input[type="text"],
.login_form input[type="password"] {
  width: 290px;
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #ccc;
}

.login_form button {
  border: none;
}

.logout {
  float: right;
  width: auto;
  height: auto;
  line-height: normal;
  padding: 5px 10px;
}

.catalog h2 {
  margin-bottom: 20px;
  padding: 5px 10px;
  border: 5px solid #ffccb6;
  border-radius: 10px;
  background-color: #ffe0e5;
}

.catalog h2 select {
  float: right;
  font-weight: normal;
}

.catalog h2.version {
  border-color: #7dd2d6;
  background-color: #b3e5e7;
}

.catalog h2.version form {
  display: none;
}

.item_wrap {
  margin-right: -20px;
  margin-left: -20px;
}

.item {
  float: left;
  width: 260px;
  margin: 0 20px 40px;
}

.item .imgwrap {
  display: table;
  table-layout: fixed;
  width: 260px;
  height: 260px;
  margin-bottom: 15px;
}

.item .imgwrap > div {
  display: table-cell;
  box-sizing: content-box;
  width: 220px;
  height: 220px;
  padding: 19px;
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}

.item h3 {
  margin-bottom: 1em;
}

.item a:hover img {
  opacity: .6;
}

.btn_pdf {
  width: auto;
  padding: 0 15px;
  border-bottom: 3px solid #005199;
}

.item_list {
  margin-bottom: 30px;
}

.item_list h3 {
  margin-bottom: 15px;
  border-bottom: 2px solid #7dd2d6;
}

.item_list li {
  margin-bottom: 10px;
}

.forbid li {
  margin-bottom: 1em;
}

.btn_form {
  padding: 0 20px 10px;
}

.big, .recieve {
  font-weight: bold;
}

.tel_num {
  margin-bottom: .5em;
  color: #0066b3;
  font-size: 30px;
  font-weight: bold;
}

.tel_num img {
  margin-right: 5px;
  vertical-align: middle;
}

.recieve {
  display: table;
}

.recieve dt, .recieve dd {
  display: table-cell;
  padding: 15px;
}

.recieve dt {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #0066b3;
  color: #fff;
}

.recieve dd {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #b3d2e8;
}

.usage {
  margin-bottom: 20px;
}

.support_list {
  margin-left: -25px;
}

.support_list li {
  float: left;
  width: 270px;
  padding: 4px;
  margin-bottom: 25px;
  margin-left: 25px;
  border: 1px solid #ccc;
  text-align: center;
}

.support_list li a {
  display: block;
  color: #f60;
  font-weight: bold;
}

.envelope span {
  display: inline-block;
  margin-right: 40px;
  vertical-align: top;
}

.prof {
  color: #f60;
}

[lang="zh-cn"] {
  font-family: "Microsoft YaHei", "微软雅黑",'SimSun', sans-serif;
}

.news .main_contents article > div:not(.related) a:link {
  text-decoration: underline;
}

.btn_conf, .btn_fix {
  box-sizing: border-box;
  width: 250px;
  height: 50px;
  line-height: 50px;
  background-color: #f60;
  border: none;
  border-bottom: 3px solid #930;
  font-family: inherit;
}

.btn_fix {
  margin-right: 1em;
  background-color: #39c;
  border-bottom-color: #2466ae;
}

.inquiry dl {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 15px;
}

.inquiry dt {
  display: table-cell;
  width: 270px;
  padding: 20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #ffcc80;
  vertical-align: top;
}

.inquiry dd {
  display: table-cell;
  padding: 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #ffeaca;
}

.inquiry input[type="text"],
.inquiry textarea,
.inquiry select {
  box-sizing: border-box;
  padding: 10px;
  border: none;
  border-radius: 6px;
}

.inquiry .label {
  margin: 0 1em;
}

.inquiry label {
  margin-right: 1em;
}

input.full,
textarea.full {
  width: 100%;
}

input.full + img,
textarea.full + img {
  margin-top: 10px;
  margin-right: 10px;
}

input.full + img + p,
textarea.full + img + p {
  margin-top: 10px;
}

input.short {
  width: 8em;
  margin-right: 10px;
}

input.short:not(:first-child) {
  margin-left: 10px;
}

.address select {
  margin-bottom: 10px;
}

.address input.full:not(:last-child) {
  margin-bottom: 10px;
}

.btns {
  margin: 40px 0;
  text-align: center;
}

.note {
  padding: 15px;
  border-radius: 8px;
  background-color: #e6ecea;
}

.thanks {
  padding: 100px 0;
  text-align: center;
}

.thanks p {
  margin-bottom: 80px;
}

.motto .c {
  padding: 40px 0;
}

.biz_list {
  margin-bottom: 20px;
  margin-left: -25px;
}

.biz_list li {
  float: left;
  width: 270px;
  margin-left: 25px;
  padding: 15px;
  border: 5px solid #deecff;
  border-radius: 16px;
}

.biz_list li p:last-of-type {
  margin-bottom: 0;
}

.amuse {
  margin-bottom: 15px;
  color: #2c71ab;
}

.amuse img {
  margin-right: 5px;
  vertical-align: middle;
}

.game {
  margin-bottom: 20px;
}

.game .flo_l {
  margin-right: 15px;
}

.game h4 {
  margin-bottom: 15px;
}

.co th,
.co td {
  box-sizing: border-box;
  padding: 15px;
  vertical-align: top;
}

.co tr:not(:last-child) th,
.co tr:not(:last-child) td {
  border-bottom: 1px solid #ccc;
}

.co th {
  width: 150px;
  font-weight: normal;
  text-align: center;
}

.co.hist th {
  padding-left: 0;
  text-align: left;
}

.co.hist tr:last-child th,
.co.hist tr:last-child td {
  border-bottom: 1px solid #ccc;
}

.map figure {
  float: right;
  margin-left: 20px;
}

.map figure img {
  display: block;
  margin-bottom: 15px;
}

.call {
  color: #0066b3;
  font-weight: bold;
}

.call img {
  margin-right: 5px;
  vertical-align: middle;
}

.ind {
  margin-bottom: 0;
  padding-left: 1em;
  text-indent: -1em;
}

.ind.mb30 {
  margin-bottom: 30px;
}

.large {
  font-weight: bold;
}
